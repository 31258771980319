import * as React from "react";
import { Link } from "gatsby";

type NavbarProps = {
    backNavbar: boolean;
}

const Navbar = ({ backNavbar }: NavbarProps) => {
  return (
    <nav>
      {backNavbar ? "☚ " : null}
      <Link to="/">Home</Link>
      {" / "}
      <Link to="/projects">Projects</Link>
      {" / "}
      <Link to="/blog">Blog</Link>
      {backNavbar ? null : " ☛"}
    </nav>
  );
};

export default Navbar;
